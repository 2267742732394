<template>
    <div class="timeline-item-description">
        <i class="avatar | small">
            <font-awesome-icon icon="user"/>
        </i>
        <span><a href="#">$USER</a> has changed state to <span
            class="badge badge-pill badge-primary" :class="'bg-' + colorLookup">{{ lookupState.text }}</span> at <time
            :datetime="timestamp">{{ timestamp }}</time>
        </span>
    </div>
</template>

<script>

import {mapState} from "vuex";

export default {
    name: 'TimelineStateChange',
    props: {
        'item': {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState(['state_options']),
        lookupState: function () {
            try {
                if (this.item.state)
                    return this.state_options.find(state => state.value === this.item.state);
            } catch (e) {
            }
            return {
                text: 'Unknown',
                value: 'unknown'
            };
        },
        colorLookup: function () {
            if (this.item.state.startsWith('closed_')) {
                return 'secondary';
            } else if (this.item.state.startsWith('pending_')) {
                return 'warning';
            } else if (this.item.state.startsWith('waiting_')) {
                return 'primary';
            } else {
                return 'danger';
            }
        },
        'timestamp': function () {
            return new Date(this.item.timestamp).toLocaleString();
        },

    }
};
</script>

<style scoped>


a {
    color: inherit;
}


.timeline-item-description {
    display: flex;
    padding-top: 6px;
    gap: 8px;
    color: var(--gray);

    img {
        flex-shrink: 0;
    }

    a {
        /*color: var(--c-grey-500);*/
        font-weight: 500;
        text-decoration: none;

        &:hover,
        &:focus {
            outline: 0; /* Don't actually do this */
            color: var(--info);
        }
    }
}

.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    flex-shrink: 0;
    width: 40px;
    height: 40px;

    &.small {
        width: 28px;
        height: 28px;
    }

    img {
        object-fit: cover;
    }
}

</style>