<template>
    <div>
        <h3 class="text-center">Events</h3>
        <ul>
            <li v-for="event in events" :key="event.id">
                {{ event.slug }}
            </li>
        </ul>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import Table from '@/components/Table';

export default {
    name: 'Dashboard',
    components: {},
    computed: {
        ...mapState(['events']),
    },
    methods: {
        ...mapActions(['loadEvents']),

    },
    mounted() {
        this.loadEvents();
    }
};
</script>

<style>
</style>