<template>
    <input
        class="form-control w-100"
        type="search"
        placeholder="Search"
        aria-label="Search"
        v-model="search_query"
        @keyup.enter="dispatchSearch"
    >
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
    name: 'SearchBox',
    data() {
        return {
            search_query: ''
        }
    },
    computed: {
        ...mapGetters(['getActiveView'])
    },
    methods: {
        ...mapActions(['searchEventItems', 'searchEventTickets']),
        isItemView() {
            return this.getActiveView === 'items' || this.getActiveView === 'item';
        },
        isTicketView() {
            return this.getActiveView === 'tickets' || this.getActiveView === 'ticket';
        },
        dispatchSearch() {
            if (this.isItemView()) {
                this.searchEventItems(this.search_query);
            } else if (this.isTicketView()) {
                this.searchEventTickets(this.search_query);
            }
        }
    }
};
</script>