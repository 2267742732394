<template>
    <div>
        <Modal v-if="isModal" title="Add Box" @close="$emit('close')">
            <template #body>
                <div>
                    <input type="text" class="form-control" placeholder="Box Name" v-model="box_name">
                </div>
            </template>
            <template #buttons>
                <button type="button" class="btn btn-secondary" @click="$emit('close')">Cancel</button>
                <button type="button" class="btn btn-success" @click="createBox({name: box_name})">Create</button>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal';
import {mapActions} from "vuex";

export default {
    name: 'AddBoxModal',
    components: {Modal},
    props: ['isModal'],
    data: () => ({
        box_name: '',
    }),
    methods: {
        ...mapActions(['createBox']),
    },
};
</script>

<style scoped>

</style>