<template>
    <div class="timeline-item-wrapper">
        <div class="timeline-item-description">
            <i class="avatar | small">
                <font-awesome-icon icon="user"/>
            </i>
            <span><a href="#">$USER</a> commented <b></b> on <time
                :datetime="item.timestamp">{{ item.timestamp }}</time></span>
        </div>
        <div class="card bg-dark">
            <div class="card-body">
                <p>{{ item.comment }}</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'TimelineComment',
    props: {
        'item': {
            type: Object,
            required: true
        }
    },
};
</script>

<style scoped>


a {
    color: inherit;
}

/* End basic CSS override */


.timeline-item-description {
    display: flex;
    padding-top: 6px;
    gap: 8px;
    color: var(--gray);

    img {
        flex-shrink: 0;
    }

    a {
        /*color: var(--c-grey-500);*/
        font-weight: 500;
        text-decoration: none;

        &:hover,
        &:focus {
            outline: 0; /* Don't actually do this */
            color: var(--info);
        }
    }
}

.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    flex-shrink: 0;
    width: 40px;
    height: 40px;

    &.small {
        width: 28px;
        height: 28px;
    }

    img {
        object-fit: cover;
    }
}


</style>