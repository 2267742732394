<template>
    <Table
        :columns="['cid', 'name','itemCount']"
        :items="loadedBoxes"
        :keyName="'cid'"
    >
        <template v-slot:header_actions>
            <button class="btn btn-success" @click.prevent="openAddBoxModal">
                <font-awesome-icon icon="plus"/>
                Create Box
            </button>
        </template>
        <template v-slot:actions="{ item }">
            <div class="btn-group">
                <button class="btn btn-secondary" @click.stop="showBoxContent(item.name)">
                    <font-awesome-icon icon="archive"/>
                </button>
                <button class="btn btn-danger" @click.stop="confirm('delete box?') && deleteBox(item.cid)"
                        title="Delete Box">
                    <font-awesome-icon icon="trash"/>
                </button>
            </div>
        </template>
    </Table>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import Table from '@/components/Table';

export default {
    name: 'Boxes',
    components: {Table},
    computed: mapState(['loadedBoxes', 'layout']),
    methods: {
        ...mapActions(['showBoxContent', 'deleteBox']),
        ...mapMutations(['openAddBoxModal']),
        confirm(message) {
            return window.confirm(message);
        },
    },
};
</script>

<style scoped>

</style>