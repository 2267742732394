<template>
    <div style="min-height: 100vh; display: flex; flex-direction: column;">
        <AddItemModal v-if="addItemModalOpen && isLoggedIn" @close="closeAddItemModal()" isModal="true"/>
        <AddTicketModal v-if="addTicketModalOpen && isLoggedIn" @close="closeAddTicketModal()" isModal="true"/>
        <AddBoxModal v-if="showAddBoxModal && isLoggedIn" @close="closeAddBoxModal()" isModal="true"/>
        <AddEventModal v-if="showAddEventModal && isLoggedIn" @close="closeAddEventModal()" isModal="true"/>
        <Navbar v-if="isLoggedIn" @addItemClicked="openAddItemModal()" @addTicketClicked="openAddTicketModal()"/>
        <router-view style="flex: 1 1;"/>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar';
import AddItemModal from '@/components/AddItemModal';
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex';
import AddTicketModal from "@/components/AddTicketModal.vue";
import AddBoxModal from "@/components/AddBoxModal.vue";
import AddEventModal from "@/components/AddEventModal.vue";

export default {
    name: 'app',
    components: {AddBoxModal, AddEventModal, Navbar, AddItemModal, AddTicketModal},
    computed: {
        ...mapState(['loadedItems', 'layout', 'toasts', 'showAddBoxModal', 'showAddEventModal']),
        ...mapGetters(['isLoggedIn']),
    },
    data: () => ({
        addItemModalOpen: false,
        addTicketModalOpen: false
    }),
    methods: {
        ...mapMutations(['removeToast', 'createToast', 'closeAddBoxModal', 'openAddBoxModal', 'closeAddEventModal']),
        ...mapActions(['loadEvents', 'scheduleAfterInit']),
        openAddItemModal() {
            this.addItemModalOpen = true;
        },
        openAddTicketModal() {
            this.addTicketModalOpen = true;
        },
        closeAddItemModal() {
            this.addItemModalOpen = false;
        },
        closeAddTicketModal() {
            this.addTicketModalOpen = false;
        }
    },
    created: function () {
        document.title = document.location.hostname;
        this.scheduleAfterInit(() => [this.loadEvents()]);
    }
};
</script>

<style>
body, html, #app {
    background: #000;
}
</style>

