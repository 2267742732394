<template>
    <table class="table table-striped table-dark" style="table-layout: fixed">
        <thead>
        <tr>
            <th scope="col"></th>
            <th scope="col" v-for="(column, index) in columns" :key="index">
                {{ column }}
            </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, i) in rows" :key="i">
            <td>{{ row }}</td>
            <td v-for="(column, j) in columns" :key="j">
                <font-awesome-icon v-if="triState(items[j][i]) === 'on'" icon="check" class="text-success"/>
                <font-awesome-icon v-else-if="triState(items[j][i]) === 'off'" icon="times" class="text-danger"/>
                <font-awesome-icon v-else icon="minus" class="text-warning"/>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>

export default {
    name: 'Matrix3D',
    props: {
        items: {
            type: Array,
            required: true
        },
        columns: {
            type: Array,
            required: true
        },
        rows: {
            type: Array,
            required: true
        },
        foldedDimension: {
            type: Array,
            required: true
        }
    },
    methods: {
        triState(list) {
            if(list.every(e => e === true)) {
                return 'on';
            } else if(list.every(e => e === false)) {
                return 'off';
            } else {
                return 'partial';
            }
        }
    },
};
</script>

<style>
</style>