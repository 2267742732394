<template>
    <Modal @close="$emit('close')">
        <template #body>
            <AuthenticatedImage
                class="img-fluid rounded mx-auto d-block mb-3 w-100"
                :src="`/media/2/${hash}/`"
                alt="Image not available."
                id="lightbox-image"
            />
        </template>
        <template #buttons>
            <button type="button" class="btn btn-secondary" @click="$emit('close')">Cancel</button>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import AuthenticatedImage from "@/components/AuthenticatedImage.vue";

export default {
    name: 'Lightbox',
    components: {Modal, AuthenticatedImage},
    props: ['hash']
};
</script>

<style>
#lightbox-image {
    max-height: 75vh;
    object-fit: contain;
}
</style>