<template>
    <img :src="image_data" :alt="src">
</template>

<style scoped>
</style>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: "AuthenticatedImage",
    props: {
        src: {
            type: String,
            required: true
        },
        cached: Boolean
    },
    data() {
        return {
            image_data: "",
            servers: []
        }
    },
    computed: {
        ...mapGetters(['getThumbnail']),
    },
    methods: {
        ...mapActions(['fetchImage']),
        ...mapMutations(['setThumbnail']),
        async loadImage() {
            const response = await this.fetchImage(this.src);
            const mime_type = response.headers.get("content-type");
            const buf = await response.arrayBuffer();
            const base64 = btoa(new Uint8Array(buf)
                .reduce((data, byte) => data + String.fromCharCode(byte), ""));
            this.image_data = "data:" + mime_type + ";base64," + base64;
            if (this.cached)
                this.setThumbnail({
                    url: this.src,
                    data: this.image_data
                });
        }
    },
    mounted() {
        setTimeout(() => {
            if (this.cached) {
                const c = this.getThumbnail(this.src);
                if (c) {
                    this.image_data = c;
                    return;
                }
            }
            this.loadImage();
        }, 0);
    }
}
</script>