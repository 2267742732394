<template>
    <div class="timeline-item-description">
        <i class="avatar | small">
            <font-awesome-icon icon="user"/>
        </i>
        <span><a href="#">$USER</a> has claimed shipping voucher
            <ClipboardButton class="btn btn-primary badge badge-pill" title="Copy shipping voucher to clipboard"
                             :payload="item.voucher">{{ item.voucher }}
                <font-awesome-icon icon="clipboard"/>
            </ClipboardButton> of type <span class="badge badge-pill badge-secondary">{{ item.voucher_type }}</span> for this ticket at <time
                :datetime="timestamp">{{ timestamp }}</time>
        </span>
    </div>
</template>

<script>

import {mapState} from "vuex";
import ClipboardButton from "@/components/inputs/ClipboardButton.vue";

export default {
    name: 'TimelineShippingVoucher',
    components: {ClipboardButton},
    props: {
        'item': {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState(['state_options']),
        'timestamp': function () {
            return new Date(this.item.timestamp).toLocaleString();
        },

    }
};
</script>

<style scoped>


a {
    color: inherit;
}


.timeline-item-description {
    display: flex;
    padding-top: 6px;
    gap: 8px;
    color: var(--gray);

    img {
        flex-shrink: 0;
    }

    a {
        /*color: var(--c-grey-500);*/
        font-weight: 500;
        text-decoration: none;

        &:hover,
        &:focus {
            outline: 0; /* Don't actually do this */
            color: var(--info);
        }
    }
}

.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    flex-shrink: 0;
    width: 40px;
    height: 40px;

    &.small {
        width: 28px;
        height: 28px;
    }

    img {
        object-fit: cover;
    }
}

</style>