<template>
    <table class="table table-striped table-dark" style="table-layout: fixed">
        <thead>
        <tr>
            <th scope="col"></th>
            <th scope="col" v-for="(column, index) in columns" :key="index">
                {{ column }}
            </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, i) in rows" :key="i">
            <td>{{ row }}</td>
            <td v-for="(column, j) in columns" :key="j">
                <font-awesome-icon v-if="items[j][i]" icon="check" class="text-success"/>
                <font-awesome-icon v-else icon="times" class="text-danger"/>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>

export default {
    name: 'Matrix2D',
    props: {
        items: {
            type: Array,
            required: true
        },
        columns: {
            type: Array,
            required: true
        },
        rows: {
            type: Array,
            required: true
        }
    },
};
</script>

<style>
</style>