<template>
    <a :href="image_data">{{ download }}</a>
</template>

<style scoped>
</style>

<script>

import {mapActions} from "vuex";

export default {
    name: "AuthenticatedDataLink",
    props: {
        href: {
            type: String,
            required: true
        },
        download: {
            type: String,
            required: false,
            default: "unnamed"
        }
    },
    data() {
        return {
            image_data: "",
            servers: []
        }
    },
    methods: {
        ...mapActions(['fetchImage']),
        loadImage() {
            this.fetchImage(this.href).then((response) => {
                const mime_type = response.headers.get("content-type");
                response.arrayBuffer().then((buf) => {
                    const base64 = btoa(new Uint8Array(buf)
                        .reduce((data, byte) => data + String.fromCharCode(byte), ""));
                    this.image_data = "data:" + mime_type + ";base64," + base64;
                });
            })
        }
    },
    mounted() {
        this.loadImage();
    }
}
</script>