<template>
    <div>
        <h3 class="text-center">Users</h3>
        <Table :items="users" :columns="['username']" :key-name="'id'">
            <template v-slot:default="{item}">
                <span>
                    {{item.groups.join(', ')}}
                </span>
            </template>
        </Table>
        <h3 class="text-center">Groups</h3>
        <Table :items="groups" :columns="['name']" :key-name="'id'">
            <template v-slot:default="{item}">
                <span>
                    {{item.members.join(', ')}}
                </span>
            </template>
        </Table>
        <Matrix2D :items="groupPermissions" :columns="groupNames" :rows="permissionNames"/>
        <h3 class="text-center">Permissions</h3>
        <Matrix3D :items="userPermissions" :columns="userNames" :rows="permissionNames"
                  :folded-dimension="eventsNames"/>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import Table from "@/components/Table.vue";
import Matrix3D from "@/components/Matrix3D.vue";
import Matrix2D from "@/components/Matrix2D.vue";

export default {
    name: 'AccessControl',
    components: {Matrix3D, Matrix2D, Table},
    computed: {
        ...mapState(['users', 'groups', 'events']),
        permissionNames() {
            return this.groups.map(g => g.permissions).flat().map(p => p.split(":")[1])
        },
        groupNames() {
            return this.groups.map(g => g.name)
        },
        eventsNames() {
            return this.events.map(e => e.slug)
        },
        userNames() {
            return this.users.map(u => u.username)
        },
        groupPermissions() {
            return this.groups.map(g => this.permissionNames.map(p => g.permissions.includes("*:" + p)))
        },
        userPermissions() {
            return this.users.map(u => this.permissionNames.map(p => this.events.map(e =>
                u.permissions.includes("*:" + p) || u.permissions.includes(e.slug + ":" + p)
            )))
        },
    },
    methods: mapActions(['loadUsers', 'loadGroups']),
    mounted() {
        this.loadUsers();
        this.loadGroups();
    }
};
</script>

<style scoped>

</style>