<template>
    <div class="timeline-item-wrapper">
        <Lightbox v-if="lightboxHash" :hash="lightboxHash" @close="closeLightboxModal()"/>
        <div class="timeline-item-description">
            <i class="avatar | small">
                <font-awesome-icon icon="user"/>
            </i>
            <span><!--a href="#">$USER</a--> linked item <span class="badge badge-secondary">#{{ item.item.uid }} </span> on <time
                :datetime="timestamp">{{ timestamp }}</time> as <span class="badge badge-primary">{{ item.status }}</span>
            </span>
        </div>
        <div class="card bg-dark">
            <div class="row">
                <div class="col" style="flex-grow: 0;">
                    <AuthenticatedImage v-if="item.item.file" cached
                                        :src="`/media/2/256/${item.item.file}/`"
                                        class="d-block card-img-left"
                                        @click="openLightboxModalWith(item.item)"
                    />
                </div>
                <div class="col">
                    <div class="card-body">
                        <h6 class="card-subtitle text-secondary">uid: {{ item.item.uid }} box: {{ item.item.box }}</h6>
                        <h6 class="card-title">{{ item.item.description }}</h6>
                        <!--div class="row mx-auto mt-2">
                            <div class="btn-group">
                                <button class="btn btn-outline-success"
                                        @click.stop="confirm('return Item?') && markItemReturned(item.item)"
                                        title="returned">
                                    <font-awesome-icon icon="check"/>
                                </button>
                                <button class="btn btn-outline-secondary" @click.stop="openEditingModalWith(item.item)"
                                        title="edit">
                                    <font-awesome-icon icon="edit"/>
                                </button>
                                <button class="btn btn-outline-danger"
                                        @click.stop="confirm('delete Item?') && deleteItem(item.item)"
                                        title="delete">
                                    <font-awesome-icon icon="trash"/>
                                </button>
                            </div>
                        </div>
                        <p>{{ item }}</p-->
                    </div>
                </div>
            </div>
        </div>
        <!--button class="show-replies">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-forward"
                 width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                 stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M15 11l4 4l-4 4m4 -4h-11a4 4 0 0 1 0 -8h1"/>
            </svg>
            Show 3 replies
            <span class="avatar-list">
                    <i class="avatar | small">
                        <font-awesome-icon icon="user"/>
                    </i>
                    <i class="avatar | small">
                        <font-awesome-icon icon="user"/>
                    </i>
                    <i class="avatar | small">
                        <font-awesome-icon icon="user"/>
                    </i>
                </span>
        </button-->
    </div>
</template>

<script>

import AuthenticatedImage from "@/components/AuthenticatedImage.vue";
import AuthenticatedDataLink from "@/components/AuthenticatedDataLink.vue";
import Lightbox from "@/components/Lightbox.vue";

export default {
    name: 'TimelineRelatedItem',
    components: {Lightbox, AuthenticatedImage, AuthenticatedDataLink},
    data() {
        return {
            lightboxHash: null,
        }
    },
    props: {
        'item': {
            type: Object,
            required: true
        }
    },
    computed: {
        'timestamp': function () {
            return new Date(this.item.timestamp).toLocaleString();
        },
        'body': function () {
            return this.item.body.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '<br/>');
        }

    },
    methods: {
        openLightboxModalWith(attachment) {
            this.lightboxHash = attachment.hash;
        },
        closeLightboxModal() { // Closes the editing modal and discards the edited copy of the item.
            this.lightboxHash = null;
        },
    },
};
</script>

<style scoped>

a {
    color: inherit;
}

.card-img-left {
    border-top-left-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px);
}

/*img {
    display: block;
    max-width: 100%;
}*/


.timeline-item-description {
    display: flex;
    padding-top: 6px;
    gap: 8px;
    color: var(--gray);

    img {
        flex-shrink: 0;
    }

    a {
        /*color: var(--c-grey-500);*/
        font-weight: 500;
        text-decoration: none;

        &:hover,
        &:focus {
            outline: 0; /* Don't actually do this */
            color: var(--info);
        }
    }
}


.card {
    border: 1px solid var(--gray);
}

.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    flex-shrink: 0;
    width: 40px;
    height: 40px;

    &.small {
        width: 28px;
        height: 28px;
    }

    img {
        object-fit: cover;
    }
}

.button {
    border: 0;
    display: inline-flex;
    vertical-align: middle;
    margin-right: 4px;
    margin-top: 12px;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    height: 32px;
    padding: 0 8px;
    background-color: var(--gray);
    flex-shrink: 0;
    cursor: pointer;
    border-radius: 99em;

    &:hover {
        background-color: var(--info);
    }

    &.square {
        border-radius: 50%;
        color: var(--gray);
        background-color: var(--dark);
        width: 32px;
        height: 32px;
        padding: 0;

        svg {
            width: 24px;
            height: 24px;
        }

        &:hover {
            color: var(--info);
        }
    }
}

.show-replies {
    color: var(--gray);
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 1rem;
    cursor: pointer;

    svg {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
    }

    &:hover,
    &:focus {
        color: var(--info);
    }
}

.avatar-list {
    display: flex;
    align-items: center;

    & > * {
        position: relative;
        box-shadow: 0 0 0 2px #fff;
        background: var(--dark);
        margin-right: -8px;
    }
}

</style>