<template>
    <div class="container-fluid px-xl-5 mt-3">
        <div class="row">
            <div class="col-xl-8 offset-xl-2">
                <div class="card bg-dark text-light mb-2" id="filters">
                    <div class="card-header">
                        <h3 class="text-center">User: {{ activeUser }}</h3>
                    </div>
                    <div class="card-body" v-if="hasPermissions">
                        <p>Your Account is activated. Got to
                            <router-link :to="{name: 'items', params: {event: getEventSlug}}">Items</router-link>
                            or
                            <router-link :to="{name: 'tickets', params: {event: getEventSlug}}">Tickets</router-link>
                        </p>
                    </div>
                    <div class="card-body" v-else>
                        <p>Your Account is not yet activated. Please contact an admin.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
    name: 'Empty',
    computed: {
        ...mapGetters(['hasPermissions', 'getEventSlug', 'activeUser']),
    },
};
</script>

<style scoped>

</style>
