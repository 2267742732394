<template>
    <Table
        :columns="['slug', 'name']"
        :items="events"
        :keyName="'slug'"
    >
        <template v-slot:header_actions>
            <button class="btn btn-success" @click.prevent="openAddEventModal">
                <font-awesome-icon icon="plus"/>
                Create Event
            </button>
        </template>
        <template v-slot:actions="{ item }">
            <div class="btn-group">
                <button class="btn btn-secondary" @click.stop="changeEvent(item)">
                    <font-awesome-icon icon="archive"/>
                    use
                </button>
                <button class="btn btn-danger" @click.stop="safeDeleteEvent(item.eid)">
                    <font-awesome-icon icon="trash"/>
                    delete
                </button>
            </div>
        </template>
    </Table>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import Table from '@/components/Table';

export default {
    name: 'Events',
    components: {Table},
    computed: mapState(['events']),
    methods: {
        ...mapActions(['changeEvent', 'deleteEvent']),
        ...mapMutations(['openAddEventModal']),
        safeDeleteEvent(id) {
            if (confirm('do you want to completely delete this event and related data?')) {
                this.deleteEvent(id)
            }
        },
    },
};
</script>

<style scoped>

</style>