<template>
    <div class="container-fluid px-xl-5 mt-3" style="color: #f8f9fa !important;">
        <div class="row">
            <div class="col-xl-8 offset-xl-2">
                <b>[en below]</b>
                <h2>Lost&Found (Deutsche Version)</h2>
                <p>Herzlich Willkommen bei Lost&Found von $Veranstaltung!</p>
                <p>Deine Aufgaben sind es verloren gegangene Gegenstände anzunehmen und zu registrieren, sowie
                    Gegenstände ihren Besitzenden zurückzubringen.</p>
                <p><b>Bitte den Inhalt des Lost+Founds nicht offen liegen lassen oder rumzeigen. Erst beschreiben
                    lassen, dann zeigen.</b></p>
                <h3>Found (Jemand bringt einen verloren gegangen Gegenstand vorbei)</h3>
                <ul>
                    <li>Möglichst viele Informationen über die Umstände heraus
                        <ul>
                            <li>Wo wurde der Gegenstand gefunden?</li>
                            <li>Zu welcher Uhrzeit wurde der Gegenstand gefunden?</li>
                        </ul>
                    </li>
                    <li>Der Gegenstand wird in das c3lf-System eingetragen
                        <ul>
                            <li>Foto vom Gegenstand mit der Webcam machen</li>
                            <li>Beschreibung des Gegenstands eintragen (gerne wie Tags behandeln die durch Leerzeichen
                                getrennt
                                sind)
                            </li>
                            <li>Nummer der Box eintragen in die der Gegenstand gelegt wird</li>
                            <li>Gegenstand in die Box legen</li>
                        </ul>
                    </li>
                </ul>
                <h3>Lost (Jemand versucht einen Gegenstand wiederzufinden)</h3>
                <ul>
                    <li>Jemand möchte einen Gegenstand wiederfinden</li>
                    <li>Die Person soll möglichst genau beschreiben was sie sucht (insbesondere bei wertvolleren
                        Dingen)
                    </li>
                    <li>Du schaust im System nach ob der Gegenstand vorhanden ist</li>
                    <li>Wenn ein passender Gegenstand gefunden ist:
                        <ul>
                            <li>Gegenstand aus der Box holen und der Person aushändigen</li>
                            <li>Grünen Haken beim Gegenstand setzen</li>
                        </ul>
                    </li>
                    <li>Wenn kein passender Gegenstand existiert:
                        <ul>
                            <li>Sagen, dass es den Gegenstand nicht gibt</li>
                            <li>Darauf verweisen dass die Person entweder zu späterem Zeitpunkt wiederkommt, oder ein
                                Ticket bei <a
                                    href="mailto:camp23@c3lf.de" target="_blank" rel="noopener">camp23@c3lf.de</a>
                                aufmacht. Außerdem ist es
                                gut möglich dass der Himmel und andere Villages eigene, kleinere Lost+Founds während des
                                Events
                                aufgemacht haben. Rumfragen lohnt sich also.
                                <ul>
                                    <li><b>Achtung: Tickets werden erst nach Ende des Events bearbeitet.</b></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <h3>Eskalation</h3>
                <ul>
                    <li>Bei Lost&amp;Found bezogenen Problemen: 1033 (Lost&amp;Found Orga) anrufen</li>
                    <li>Bei Engel bezogenen Problemen: 1023 (Himmel) anrufen</li>
                </ul>
                <hr>
                <h2>Lost&Found (English version)</h2>
                <p>Welcome to Lost&Found of $Event!</p>
                <p>Your tasks are to accept and register lost items, as well as to return items to their owners.</p>
                <p><b>Please do not leave the contents of the Lost+Found lying around or show them around. Ask for a
                    description of the item first, then show them (if the description seems correct).</b></p>
                <h3>Found (Someone brings a lost item)</h3>
                <ul>
                    <li>Get as much information as possible about the circumstances
                        <ul>
                            <li>Where was the item found?</li>
                            <li>At what time was the item found?</li>
                        </ul>
                    </li>
                    <li>The item is entered into the c3lf system
                        <ul>
                            <li>Take a photo of the item with the webcam</li>
                            <li>Enter a description of the item (preferably treat it like tags separated by spaces)</li>
                            <li>Enter the number of the box into which the item is to be placed</li>
                            <li>Place the item in the box</li>
                        </ul>
                    </li>
                </ul>
                <h3>Lost (Someone tries to find an item)</h3>
                <ul>
                    <li>Someone wants to find an item</li>
                    <li>The person should describe as precisely as possible what they are looking for (especially for
                        more
                        valuable things)
                    </li>
                    <li>You check the system to see if the item is available</li>
                    <li>If a suitable item is found:
                        <ul>
                            <li>Take the item out of the box and hand it over to the person</li>
                            <li>Set a green check mark for the item</li>
                        </ul>
                    </li>
                    <li>If no suitable item exists:
                        <ul>
                            <li>Say that the item does not exist</li>
                            <li>Point out that the person either comes back at a later time, or opens a ticket at <a
                                href="mailto:camp23@c3lf.de" target="_blank" rel="noopener">camp23@c3lf.de</a>. It is
                                also possible that
                                the Himmel and other Villages have their own, smaller Lost+Founds during the event. So
                                it's worth asking
                                around.
                                <ul>
                                    <li><b>Attention: Tickets will not be processed until after the event.</b></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <h3>Escalation</h3>
                <ul>
                    <li>For Lost&amp;Found related problems: call 1033 (Lost&amp;Found Orga)</li>
                    <li>For angel related problems: call 1023 (Heaven)</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HowTo',
};
</script>

<style scoped>
* {
    color: #c8c8c8 !important;
}

</style>
