<template>
    <div>
        <h3>Shipping Vouchers</h3>
        <div class="mt-3">
            <h5>Shipping Voucher Types</h5>
            <span v-for="(type, key) in availableShippingVoucherTypes" :key="key" class="mr-2">
                <span v-if="type.count > 2" class="badge badge-success">{{ type.name }} - {{ type.count }}</span>
                <span v-else-if="type.count > 0" class="badge badge-warning" v-if="type.count > 0">
                    {{ type.name }} - {{ type.count }}
                </span>
                <span v-else class="badge badge-danger">{{ type.name }}</span>
            </span>
        </div>
        <div class="mt-3">
            <h5>Available Shipping Vouchers</h5>
            <ul>
                <li v-for="voucher in shippingVouchers" :key="voucher.voucher">
                    <span v-if="voucher.issue_thread == null">{{ voucher.type }} - {{ voucher.voucher }}</span>
                    <span v-else><s style="color:var(--danger)">{{ voucher.type }} - {{ voucher.voucher }}</s> used in
                        <router-link :to="'/'+ getEventSlug + '/ticket/' + voucher.issue_thread">#{{
                                voucher.issue_thread
                            }}</router-link></span>
                </li>
            </ul>
        </div>
        <div class="mt-3">
            <textarea class="form-control mb-3" rows="5" placeholder="Shipping Voucher List" v-model="bulk_vouchers"
                      v-if="bulk"></textarea>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Shipping Voucher" v-model="voucher" v-if="!bulk">
                <select class="form-control" v-model="type">
                    <option v-for="it in Object.keys(shippingVoucherTypes)" :value="it">{{
                            shippingVoucherTypes[it]
                        }}
                    </option>
                </select>
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <input type="checkbox" v-model="bulk" class="mr-2" id="bulk" style="margin: 0;">
                        <label for="bulk" style="margin: 0;">Bulk</label>
                    </div>
                </div>
                <button class="btn btn-primary form-control" @click="createSingleOrBulkShippingVoucher">
                    <font-awesome-icon icon="plus"/>
                    {{ (bulk ? "Add Shipping Vouchers" : "Add Shipping Voucher") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import Table from '@/components/Table';

export default {
    name: 'Shipping',
    components: {Table},
    data() {
        return {
            voucher: '',
            bulk_vouchers: '',
            type: '2kg-eu',
            bulk: false,
        };
    },
    computed: {
        ...mapState(['shippingVouchers', 'shippingVoucherTypes']),
        ...mapGetters(['getEventSlug', 'availableShippingVoucherTypes']),
    },
    methods: {
        ...mapActions(['fetchShippingVouchers', 'createShippingVoucher']),
        createSingleOrBulkShippingVoucher() {
            if (this.bulk) {
                const list = this.bulk_vouchers.split('\n');
                if (confirm('Are you sure you want to add ' + list.length + ' shipping vouchers as ' + this.type + '?')) {
                    const jobs = list.map(voucher => {
                        return this.createShippingVoucher({voucher: voucher.trim(), type: this.type});
                    });
                    Promise.all(jobs).then(() => {
                        this.bulk_vouchers = '';
                    });
                }
            } else {
                this.createShippingVoucher({voucher: this.voucher, type: this.type}).then(() => {
                    this.voucher = '';
                });
            }
        },
    },
    mounted() {
        this.fetchShippingVouchers();
    },
};
</script>

<style scoped>

</style>