<template>
    <button @click="fillClipboard" class="btn" :title="payload">
        <slot></slot>
    </button>
</template>

<script>

export default {
    name: 'ClipboardButton',
    props: ['payload'],
    methods: {
        fillClipboard() {
            navigator.clipboard.writeText(this.payload);
        },
    }
};
</script>
