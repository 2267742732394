<template>
    <div>
        <Modal v-if="isModal" title="Add Item" @close="$emit('close')">
            <template #body>
                <EditItem :item="item"/>
            </template>
            <template #buttons>
                <button type="button" class="btn btn-secondary" @click="$emit('close')">Cancel</button>
                <button type="button" class="btn btn-success" @click="saveNewItem()">Save new Item</button>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal';
import EditItem from '@/components/EditItem';
import {mapActions, mapState} from "vuex";

export default {
    name: 'AddItemModal',
    components: {Modal, EditItem},
    props: ['isModal'],
    data: () => ({
        item: {}
    }),
    computed: {
        ...mapState(['lastUsed'])
    },
    methods: {
        ...mapActions(['postItem', 'loadBoxes', 'scheduleAfterInit']),
        saveNewItem() {
            this.postItem(this.item).then(() => {
                this.$emit('close');
            });
        }
    },
    created() {
        this.item = {box: this.lastUsed.box || '', cid: this.lastUsed.cid || ''};
    },
    mounted() {
        this.scheduleAfterInit(() => [this.loadBoxes()]);
    }
};
</script>

<style scoped>

</style>