<template>
    <AsyncLoader :loaded="loadedItems.length > 0">
        <div class="container-fluid px-xl-5 mt-3">
            <Modal title="Edit Item" v-if="editingItem" @close="closeEditingModal()">
                <template #body>
                    <EditItem
                        :item="editingItem"
                        badge="uid"
                    />
                </template>
                <template #buttons>
                    <button type="button" class="btn btn-secondary" @click="closeEditingModal()">Cancel</button>
                    <button type="button" class="btn btn-success" @click="saveEditingItem()">Save Changes</button>
                </template>
            </Modal>
            <Lightbox v-if="lightboxHash" :hash="lightboxHash" @close="closeLightboxModal()"/>
            <div class="row" v-if="layout === 'table'">
                <div class="col-xl-8 offset-xl-2">
                    <Table
                        :columns="['uid', 'description', 'box']"
                        :items="loadedItems"
                        :keyName="'uid'"
                        @itemActivated="openLightboxModalWith($event)"
                    >
                        <template #actions="{ item }">
                            <div class="btn-group">
                                <button class="btn btn-success"
                                        @click.stop="confirm('return Item?') && markItemReturned(item)"
                                        title="returned">
                                    <font-awesome-icon icon="check"/>
                                </button>
                                <button class="btn btn-secondary" @click.stop="openEditingModalWith(item)" title="edit">
                                    <font-awesome-icon icon="edit"/>
                                </button>
                                <button class="btn btn-danger" @click.stop="confirm('delete Item?') && deleteItem(item)"
                                        title="delete">
                                    <font-awesome-icon icon="trash"/>
                                </button>
                            </div>
                        </template>
                    </Table>
                </div>
            </div>
            <Cards
                v-if="layout === 'cards'"
                :columns="['uid', 'description', 'box']"
                :items="loadedItems"
                :keyName="'uid'"
                v-slot="{ item }"
                @itemActivated="openLightboxModalWith($event)"
            >
                <AuthenticatedImage v-if="item.file" cached
                                    :src="`/media/2/256/${item.file}/`"
                                    class="card-img-top img-fluid"
                />
                <div class="card-body">
                    <h6 class="card-title">{{ item.description }}</h6>
                    <h6 class="card-subtitle text-secondary">uid: {{ item.uid }} box: {{ item.box }}</h6>
                    <div class="row mx-auto mt-2">
                        <div class="btn-group">
                            <button class="btn btn-outline-success"
                                    @click.stop="confirm('return Item?') && markItemReturned(item)" title="returned">
                                <font-awesome-icon icon="check"/>
                            </button>
                            <button class="btn btn-outline-secondary" @click.stop="openEditingModalWith(item)"
                                    title="edit">
                                <font-awesome-icon icon="edit"/>
                            </button>
                            <button class="btn btn-outline-danger"
                                    @click.stop="confirm('delete Item?') && deleteItem(item)"
                                    title="delete">
                                <font-awesome-icon icon="trash"/>
                            </button>
                        </div>
                    </div>
                </div>
            </Cards>
        </div>
    </AsyncLoader>
</template>

<script>
import Table from '@/components/Table';
import Cards from '@/components/Cards';
import Modal from '@/components/Modal';
import EditItem from '@/components/EditItem';
import {mapActions, mapGetters, mapState} from 'vuex';
import Lightbox from '../components/Lightbox';
import AuthenticatedImage from "@/components/AuthenticatedImage.vue";
import AsyncLoader from "@/components/AsyncLoader.vue";

export default {
    name: 'Items',
    data: () => ({
        lightboxHash: null,
        editingItem: null,
    }),
    components: {AsyncLoader, AuthenticatedImage, Lightbox, Table, Cards, Modal, EditItem},
    computed: {
        ...mapState(['loadedItems']),
        ...mapGetters(['layout']),
    },
    methods: {
        ...mapActions(['deleteItem', 'markItemReturned', 'loadEventItems', 'updateItem', 'scheduleAfterInit']),
        openLightboxModalWith(item) {
            this.lightboxHash = item.file;
        },
        closeLightboxModal() { // Closes the editing modal and discards the edited copy of the item.
            this.lightboxHash = null;
        },
        openEditingModalWith(item) { // Opens the editing modal with a copy of the selected item.
            this.editingItem = item;
        },
        closeEditingModal() {
            this.editingItem = null;
        },
        saveEditingItem() { // Saves the edited copy of the item.
            this.updateItem(this.editingItem);
            this.closeEditingModal();
        },
        confirm(message) {
            return window.confirm(message);
        }
    },
    mounted() {
        this.scheduleAfterInit(() => [this.loadEventItems()]);
    }
};
</script>

<style scoped>

</style>