<template>
    <div>
        <Modal v-if="isModal" title="Add Event" @close="$emit('close')">
            <template #body>
                <div>
                    <div class="row mb-3">
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Event Title"
                                   v-model="data.name">
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Event Slug" v-model="data.slug">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <input type="date" class="form-control" title="Buildup Start" v-model="data.pre_start"
                                   :max="data.start" @focus="prepare_date_field">
                        </div>
                        <div class="col">
                            <input type="date" class="form-control" title="Official Event Start" v-model="data.start"
                                   :min="data.pre_start" :max="data.end" @focus="prepare_date_field">
                        </div>
                        <div class="col">
                            <input type="date" class="form-control" title="Official Event End" v-model="data.end"
                                   :min="data.start" :max="data.post_end" @focus="prepare_date_field">
                        </div>
                        <div class="col">
                            <input type="date" class="form-control" title="Teardown End" v-model="data.post_end"
                                   :min="data.end" @focus="prepare_date_field">
                        </div>
                    </div>
                    <small>The buildup and teardown dates only relate to the timeframe in which the c3lf team is
                        present.</small>
                </div>
            </template>
            <template #buttons>
                <button type="button" class="btn btn-secondary" @click="$emit('close')">Cancel</button>
                <button type="button" class="btn btn-success" @click="createEvent(data).then(()=>$emit('close'))">
                    Create
                </button>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal';
import {mapActions} from "vuex";

export default {
    name: 'AddEventModal',
    components: {Modal},
    props: ['isModal'],
    data: () => ({
        data: {
            name: '',
            slug: '',
            start: null,
            end: null,
            pre_start: null,
            post_end: null,
        },
        errors: {},
    }),
    methods: {
        ...mapActions(['createEvent']),
        validate_data() {
            return this.pre_start <= this.start && this.start <= this.end && this.end <= this.post_end && !!this.slug && !!this.name;
        },
        prepare_date_field(e) {
            if (!e.target.value) {
                if (e.target.min) {
                    e.target.value = e.target.min
                } else if (e.target.max) {
                    e.target.value = e.target.max
                }
            }
        }
    },
};
</script>

<style scoped>

</style>