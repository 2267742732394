<template>
    <AsyncLoader :loaded="tickets.length > 0">
        <div class="container-fluid px-xl-5 mt-3">
            <div class="row">
                <div class="col-xl-8 offset-xl-2">
                    <Table
                        :columns="['id', 'name', 'state', 'last_activity', 'assigned_to', 'actions', 'actions2']"
                        :items="tickets.map(formatTicket)"
                        :keyName="'id'"
                        v-if="layout === 'table'"
                    >
                        <template v-slot:actions="{item}">
                            <div class="btn-group">
                                <a class="btn btn-primary" :href="'/'+ getEventSlug + '/ticket/' + item.id" title="view"
                                   @click.prevent="gotoDetail(item)">
                                    <font-awesome-icon icon="eye"/>
                                    View
                                </a>
                            </div>
                        </template>
                    </Table>
                </div>
            </div>
            <CollapsableCards v-if="layout === 'tasks'" :items="tickets"
                              :columns="['id', 'name', 'last_activity', 'assigned_to']"
                              :keyName="'state'" :sections="['pending_new', 'pending_open','pending_shipping',
                          'pending_physical_confirmation','pending_return','pending_postponed'].map(stateInfo)">
                <template #section_header="{index, section, count}">
                    {{ section.text }} <span class="badge badge-light ml-1">{{ count }}</span>
                </template>
                <template #section_body="{item}">
                    <tr>
                        <td>{{ item.id }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.last_activity }}</td>
                        <td>{{ item.assigned_to }}</td>
                        <td>
                            <div class="btn-group">
                                <a class="btn btn-primary" :href="'/'+ getEventSlug + '/ticket/' + item.id" title="view"
                                   @click.prevent="gotoDetail(item)">
                                    <font-awesome-icon icon="eye"/>
                                    View
                                </a>
                            </div>
                        </td>
                    </tr>
                </template>
            </CollapsableCards>
        </div>
    </AsyncLoader>
</template>

<script>
import Cards from '@/components/Cards';
import Modal from '@/components/Modal';
import EditItem from '@/components/EditItem';
import {mapActions, mapGetters, mapState} from 'vuex';
import Lightbox from '../components/Lightbox';
import Table from '@/components/Table';
import CollapsableCards from "@/components/CollapsableCards.vue";
import AsyncLoader from "@/components/AsyncLoader.vue";

export default {
    name: 'Tickets',
    components: {AsyncLoader, Lightbox, Table, Cards, Modal, EditItem, CollapsableCards},
    computed: {
        ...mapState(['tickets']),
        ...mapGetters(['stateInfo', 'getEventSlug', 'layout']),
    },
    methods: {
        ...mapActions(['loadTickets', 'fetchTicketStates', 'scheduleAfterInit']),
        gotoDetail(ticket) {
            this.$router.push({name: 'ticket', params: {id: ticket.id}});
        },
        formatTicket(ticket) {
            return {
                id: ticket.id,
                name: ticket.name,
                state: this.stateInfo(ticket.state).text,
                stateColor: this.stateInfo(ticket.state).color,
                last_activity: ticket.last_activity,
                assigned_to: ticket.assigned_to
            };
        }
    },
    mounted() {
        this.scheduleAfterInit(() => [this.fetchTicketStates(), this.loadTickets()]);
    }
};
</script>

<style scoped>

</style>